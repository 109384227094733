
import Vue from 'vue'
import dayjs from 'dayjs'

import ScrollablePanels from '@/components/molecules/ScrollablePanels2.vue'
import DatePager from '@/components/atoms/DatePager.vue'

import { dailyScheduleModule } from '@/store/viewModules/work-schedule/dailyShceduleModule'
import { ShiftPatternResponse, WorkSchedulePlacementSchema,  AttendanceScheduleMonthlySchema, AttendanceScheduleChildPartSchema, AttendanceScheduleDailyPartSchema, SearchNurseryAttendanceResponse } from 'chaild-api/lib'

import { attendanceScheduleModule } from '@/store/viewModules/work-schedule/attendanceScheduleModule'
import { attendanceModule } from '@/store/dataModules/attendanceModule'
import LocalDataService from '@/service/LocalDataService'


interface DataType {
  isFetching: boolean;
  isCalcOpen: boolean;
  currentTab: number;
  searchFilters: any;
  selectedClassId: number | string | null;
  isSelecting: boolean;
}

interface DataRow {
  name: string;
  items: ScheduleHour[];
}

interface ScheduleHour {
  hour: number;
  items: QuarterlyShcedule[];
}

interface ScheduleTableRowItem {
  name: string;
  attendanceCount: number;
  offCount: number;
  schedules: AttendanceScheduleDailyPartSchema[];
  child: AttendanceScheduleChildPartSchema;
}

interface QuarterlyShcedule {
  abbreviation?: string | null;
  color?: string | null;
  isFilled: boolean;
  inSchool?: boolean;
  minute: number;
  requisite?: {
    govExpected: number | null;
    govActual: number | null;
    expected: number | null;
    actual: number | null;
  };
  sufficient?: {
    expected: number | null;
    actual: number | null;
  };
  attendance?: {
    age0: number | null;
    age1: number | null;
    age2: number | null;
    age3: number | null;
    age4: number | null;
  };
}

const minutes = [0, 15, 30, 45]
const hours = [...Array(24)].map((emp, i) => i)
const emptySchedule: ScheduleHour[] = hours.map((hour) => {
  const items: QuarterlyShcedule[] = minutes.map((minute) => ({
    isFilled: false,
    minute
  }))
  return {
    hour,
    items
  }
})

export default Vue.extend({
  components: {
    ScrollablePanels,
    DatePager,
  },
  data: (): DataType => ({
    isFetching: false,
    isCalcOpen: true,
    currentTab: 0, // デフォルトでタブ1を表示
    searchFilters: {
        date: dayjs().format("YYYY-MM-DD"),
        age: "",
        classId: "",
        gender: "",
        status: "" as string,
        limit: 1000,
        skip: 0,
      },
    isSelecting: false,
    selectedClassId: null,
  }),

  watch: {
    yearMonthDate(val: string) {
      this.searchFilters.date = dayjs(val).format('YYYY-MM-DD');
      this.searchAttendance();
    },
    selectedClassId: {
      deep: true,
      async handler() {
        this.searchFilters.classId = this.selectedClassId
        await this.searchAttendance();
      },
    },
  },

  computed: {
    yearMonthDate: {
      get() {
        return dailyScheduleModule.yearMonthDate
      },
      set(value: string) {
        dailyScheduleModule.setYearMonthDate(value)
        this.fetchDailySchedule()
      }
    },
    classes() {
      return attendanceScheduleModule.classes
    },
    dailySchedule(): ShiftPatternResponse[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.items
      }
      return []
    },
    placements(): WorkSchedulePlacementSchema[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.placements
      }
      return []
    },
    minutes(): number[] {
      return [0, 15, 30, 45]
    },
    hours() {
      return [...Array(24)].map((emp, i) => i)
    },
    emptySchedule(): ScheduleHour[] {
      return this.hours.map((hour) => {
        const items: QuarterlyShcedule[] = this.minutes.map((minute) => ({
          isFilled: false,
          minute
        }))
        return {
          hour,
          items
        }
      })
    },
    scheduleTableRows(): DataRow[] {
      return this.dailySchedule.map((schedule) => {
        let name = `${schedule.nurseryUser.lastName} ${schedule.nurseryUser.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        const items = [...this.emptySchedule].map((hourItem) => {
          const minItems = hourItem.items.map((minute) => {
            const timeAsInt = (hourItem.hour * 60) + minute.minute

            const findSchedule = schedule.timetables.find((tt) => tt.startMin <= timeAsInt && tt.endMin > timeAsInt)
            const findAttendance = schedule.timecards.find((tc) => tc.startMin <= timeAsInt && tc.endMin > timeAsInt);

            if (findSchedule) {
              return {
                isFilled: true,
                inSchool: findAttendance ? findAttendance.inSchool : false,
                abbreviation: findSchedule.abbreviation,
                color: findSchedule.color,
                minute: minute.minute
              }
            }

            return {
              isFilled: false,
              inSchool: findAttendance ? findAttendance.inSchool : false,
              minute: minute.minute
            }
          })

          return {
            hour: hourItem.hour,
            items: minItems
          }
        })

        return {
          name,
          items
        }
      })
    },
    tableCols() {
      return emptySchedule.map((s) => {
        const items = s.items.map((item) => {
          const min = s.hour * 60 + item.minute
          const find = this.placements.find((p) => {
            return p.startMin === min
          })
          console.log(find)
          if (find) {
            item.requisite = find.requisite
            item.sufficient = find.sufficient
            item.attendance = find.attendance?.expected
          }
          return item
        })
        return {
          hour: s.hour,
          items
        }
      })
    },
    attendanceSchedules() {
      return attendanceScheduleModule.attendanceSchedules
    },
    childScheduleTableRows(): ScheduleTableRowItem[] {
      return this.attendanceSchedules.filter((schedule: AttendanceScheduleMonthlySchema) => {
        if (!this.selectedClassId) {
          return true
        }
        const findClass = schedule.child.nurseryClasses.find((nc) => nc.classId === this.selectedClassId)
        if (findClass) {
          return true
        }
        return false
      }).map((schedule: AttendanceScheduleMonthlySchema) => {
        let name = `${schedule.child.lastName} ${schedule.child.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        // dailySchedulesから日付が2024-09-02のものだけを抽出
        const filteredSchedules = schedule.dailySchedules.filter((dailySchedule) => dailySchedule.date === this.yearMonthDate);

        return {
          name,
          attendanceCount: 0,
          offCount: 0,
          //schedules: schedule.dailySchedules,
          schedules: filteredSchedules,
          child: schedule.child
        }
      })
    },
    attendances: function (): SearchNurseryAttendanceResponse | null {
      return attendanceModule.attendances;
    },
  },
  methods: {
    async fetchDailySchedule() {
      this.isFetching = true
      dailyScheduleModule.setDailySchedule(null)
      await dailyScheduleModule.getDailySchedule()
      this.isFetching = false
    },
    async downloadShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    async downloadMonthlyShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlMonthlyShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    toggleCalc() {
      this.isCalcOpen = !this.isCalcOpen
    },
    async searchAttendance() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId) {
        await attendanceModule
          .searchAttendance({
            nurseryId,
            ...this.searchFilters,
          })
          .catch();
      }
    },
    formatHour(date: string | null | undefined) {
      if (date) {
        return dayjs(date).format("H");
      }
      return null;
    },
    formatMin(date: string | null | undefined) {
      if (date) {
        const minuteNumber = parseInt(dayjs(date).format("m"), 10);
        return minuteNumber
      }
      return null;
    },
    syncScroll(source: string) {
      // nextTickでDOMがレンダリングされてからrefを参照
      this.$nextTick(() => {
        const StaffleftPanel = this.$refs.StaffleftPanel as HTMLElement | null;
        const StaffcenterPanel = this.$refs.StaffcenterPanel as HTMLElement | null;
        const ChildleftPanel = this.$refs.ChildleftPanel as HTMLElement | null;
        const ChildcenterPanel = this.$refs.ChildcenterPanel as HTMLElement | null;

        // refが正しく取得できた場合にスクロール同期を行う
        if (StaffleftPanel && StaffcenterPanel) {
          if (source === 'staffleft') {
            StaffcenterPanel.scrollTop = StaffleftPanel.scrollTop;
          } else if (source === 'staffcenter') {
            StaffleftPanel.scrollTop = StaffcenterPanel.scrollTop;
          } 
        }
        if (ChildleftPanel && ChildcenterPanel) {
          if (source === 'childleft') {
            ChildcenterPanel.scrollTop = ChildleftPanel.scrollTop;
          } else if (source === 'childcenter') {
            ChildleftPanel.scrollTop = ChildcenterPanel.scrollTop;
          } 
        }
      });
    },
  },
  async mounted() {
    const queries = this.$route.query
    if (queries.date) {
      const dateString = queries.date as string
      const date = dayjs(dateString).format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    } else {
      const date = dayjs().format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    }

    this.fetchDailySchedule();

    await Promise.all([
      ///attendance-schedule/${year}/${month}/childrenを呼び出す（園児予定一覧のAPI）
      attendanceScheduleModule.listAttendanceSchedules(),
      attendanceScheduleModule.listClass()
    ]);
    ////GET/attendance/searchを呼び出す（出欠データのAPI）
    await this.searchAttendance();
  }
})
