
import Vue from 'vue';

import MonthPager from '@/components/atoms/MonthPager.vue';
import MonthDatePicker from '@/components/organisms/work-schedule/MonthDatePicker.vue'
import ScrollablePanels2 from '@/components/molecules/ScrollablePanels2.vue'
import HourMinuteInput from '@/components/molecules/HourMinuteInput.vue'
import dayjs from 'dayjs';
import { AttendanceScheduleMonthlySchema, AttendanceScheduleChildPartSchema, AttendanceScheduleDailyPartSchema } from 'chaild-api/lib';
import { attendanceScheduleModule } from '@/store/viewModules/work-schedule/attendanceScheduleModule'

interface DataType {
  isFetching: boolean;
  isUpdatingSchedule: boolean;

  selectedClassId: number | string | null;

  addingUser: AttendanceScheduleChildPartSchema | null;
  addingDate: string | null;
  addingSchedule: UpdateChildAttendanceScheduleRequestParams | null;

  editingSchedule: AttendanceScheduleDailyPartSchema | null;
  editingUser: AttendanceScheduleChildPartSchema | null;

  bulkAddingUser: AttendanceScheduleChildPartSchema | null;
  bulkAddingSchedule: UpdateBulkAttendanceScheduleRequestParams | null;
}

interface ScheduleTableRowItem {
  name: string;
  attendanceCount: number;
  offCount: number;
  schedules: AttendanceScheduleDailyPartSchema[];
  child: AttendanceScheduleChildPartSchema;
}

interface UpdateChildAttendanceScheduleRequestParams {
  date: string | number;
  childId: string | number;
  abbreviation?: string;
  color?: string;
  status?: 'attending' | 'isAbsent';
  statusReason?: string;
  statusComment?: string;
  startMin?: number;
  endMin?: number;
}

interface UpdateBulkAttendanceScheduleRequestParams {
  childId: string | number;
  abbreviation?: string;
  color?: string;
  status?: 'attending' | 'isAbsent';
  statusReason?: string;
  statusComment?: string;
  startMin?: number;
  endMin?: number;
  dates: number[];
}

const AbsenseReasons = [
  {
    text: '発熱',
    value: 'fever'
  },
  {
    text: '家庭の事情',
    value: 'personal'
  },
  {
    text: '溶連菌感染症',
    value: 'infStrep'
  },
  {
    text: 'マイコプラズマ肺炎',
    value: 'infMycoplasma'
  },
  {
    text: '手足口病',
    value: 'infHFMD'
  },
  {
    text: '伝染性紅斑（りんご病）',
    value: 'infSlappedCheek'
  },
  {
    text: 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）',
    value: 'infStomach'
  },
  {
    text: 'ヘルパンギーナ',
    value: 'infHerpangina'
  },
  {
    text: 'RSウィルス感染症',
    value: 'infRS'
  },
  {
    text: '帯状疱疹',
    value: 'infZoster'
  },
  {
    text: '突発性発しん',
    value: 'infRoseola'
  },
  {
    text: '伝染性膿痂疹（とびひ）',
    value: 'infImpetigo'
  },
  {
    text: 'その他',
    value: 'others'
  },
]

export default Vue.extend({
  components: {
    MonthPager,
    ScrollablePanels2,
    HourMinuteInput,
    MonthDatePicker,
},
  data: (): DataType => {
    return {
      isFetching: false,
      isUpdatingSchedule: false,

      selectedClassId: null,

      addingUser: null,
      addingDate: null,
      addingSchedule: null,

      editingSchedule: null,
      editingUser: null,

      bulkAddingUser: null,
      bulkAddingSchedule: null,
    };
  },
  computed: {
    yearMonth: {
      get(): string {
        return attendanceScheduleModule.yearMonth
      },
      set(value: string) {
        this.onYearMonthChange(value)
      }
    },
    classes() {
      return attendanceScheduleModule.classes
    },
    attendanceSchedules() {
      return attendanceScheduleModule.attendanceSchedules
    },
    scheduleTableRows(): ScheduleTableRowItem[] {
      return this.attendanceSchedules.filter((schedule: AttendanceScheduleMonthlySchema) => {
        if (!this.selectedClassId) {
          return true
        }
        const findClass = schedule.child.nurseryClasses.find((nc) => nc.classId === this.selectedClassId)
        if (findClass) {
          return true
        }
        return false
      }).map((schedule: AttendanceScheduleMonthlySchema) => {
        let name = `${schedule.child.lastName} ${schedule.child.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        return {
          name,
          attendanceCount: 0,
          offCount: 0,
          schedules: schedule.dailySchedules,
          child: schedule.child
        }
      })
    },
    numberOfDates(): number {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth()
      return daysInMonth
    },
    tableDates(): Array<string> {
      const daysInMonth = dayjs(this.yearMonth).daysInMonth()
      const startOfMonth = dayjs(this.yearMonth).startOf('month')
      const dates = [...Array(daysInMonth)].map((emp, i) => {
        const date = dayjs(startOfMonth).add(i, 'day').locale('ja').format('M/D (ddd)')
        return date
      })

      return dates
    },
    isAddScheduleModalOpen: {
      get() {
        if (this.addingUser && this.addingDate) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.addingUser = null
          this.addingDate = null
          this.addingSchedule = null
        }
      }
    },
    isBulkAddScheduleModalOpen: {
      get() {
        if (this.bulkAddingUser) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.bulkAddingUser = null
          this.bulkAddingSchedule = null
        }
      }
    },
    isEditScheduleModalOpen: {
      get() {
        if (this.editingSchedule && this. editingUser) {
          return true
        }
        return false
      },
      set(value: boolean) {
        if (!value) {
          this.editingSchedule = null
          this.editingUser = null
        }
      }
    },
    absenseReasons() {
      return AbsenseReasons
    },
    absenseOptions() {
      return [
        {
          text: '出席',
          value: 'attending'
        },
        {
          text: '欠席',
          value: 'isAbsent'
        },
      ]
    },
  },
  methods: {
    async onYearMonthChange(value: string) {
      this.isFetching = true
      await attendanceScheduleModule.onYearMonthChange(value)
      this.isFetching = false
    },
    openAddScheduleModal(child: AttendanceScheduleChildPartSchema, date: string) {
      this.addingUser = child
      this.addingDate = date
      this.addingSchedule = {
        childId: child.childId,
        date: date,
      }

      if (child.defaultStartMin) {
        this.addingSchedule.startMin = child.defaultStartMin
      }

      if (child.defaultEndMin) {
        this.addingSchedule.endMin = child.defaultEndMin
      }
    },
    openEditScheduleModal(child: AttendanceScheduleChildPartSchema, schedule: AttendanceScheduleDailyPartSchema) {
      this.editingUser = child
      this.editingSchedule = schedule
    },
    openBulkAddScheduleModal(child: AttendanceScheduleChildPartSchema) {
      this.bulkAddingUser = child

      this.bulkAddingSchedule = {
        childId: child.childId,
        dates: [],
      }

      if (child.defaultStartMin) {
        this.bulkAddingSchedule.startMin = child.defaultStartMin
      }

      if (child.defaultEndMin) {
        this.bulkAddingSchedule.endMin = child.defaultEndMin
      }
    },
    async addSchedule() {
      if (this.addingSchedule) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.updateChildAttendanceSchedule(this.addingSchedule)
        this.isUpdatingSchedule = false
        this.isAddScheduleModalOpen = false
      }
    },
    async addDefaultSchedules() {
      this.isUpdatingSchedule = true
      this.isFetching = true
      await attendanceScheduleModule.addDefaultAttendanceSchedules();
      this.isUpdatingSchedule = false
      this.isFetching = false
    },
    async addBulkSchedule() {
      if (this.bulkAddingSchedule) {
        this.isUpdatingSchedule = true
        const schedule = { ...this.bulkAddingSchedule } as UpdateBulkAttendanceScheduleRequestParams

        await attendanceScheduleModule.updateChildAttendanceMonthlySchedule({
          childId: this.bulkAddingSchedule.childId,
          dailySchedules: schedule.dates.map((date) => {
            const d = dayjs(this.yearMonth).date(date).format('YYYY-MM-DD')
            
            return {
              date: d,
              abbreviation: null,
              color: null,
              status: schedule.status,
              statusReason: schedule.statusReason,
              statusComment: schedule.statusComment,
              startMin: schedule.startMin,
              endMin: schedule.endMin
            } as AttendanceScheduleDailyPartSchema
          })
        })
        this.isUpdatingSchedule = false
        this.isBulkAddScheduleModalOpen = false
      }
    },
    async saveEditingShcedule() {
      if (this.editingSchedule && this.editingUser) {
        this.isUpdatingSchedule = true
        await attendanceScheduleModule.updateChildAttendanceSchedule({
          date: this.editingSchedule.date,
          childId: this.editingUser.childId,
          abbreviation: this.editingSchedule.abbreviation || undefined,
          color: this.editingSchedule.color || undefined,
          status: this.editingSchedule.status || undefined,
          statusReason: this.editingSchedule.statusReason || undefined,
          startMin: this.editingSchedule.startMin || undefined,
          statusComment: this.editingSchedule.statusComment || undefined,
          endMin: this.editingSchedule.endMin || undefined
        })
        this.isUpdatingSchedule = false
        this.isEditScheduleModalOpen = false
      }
    },
    getRegisteredSchedule(date: number) {
      if (this.bulkAddingUser) {
        const findSchedules = this.attendanceSchedules.find((as) => as.child.childId === this.bulkAddingUser!.childId)
        if (findSchedules) {
          const targetDate = dayjs(`${this.yearMonth}-${date}`).format('YYYY-MM-DD')
          const dateSchedule = findSchedules.dailySchedules.find((ds) => ds.date === targetDate)
          if (dateSchedule) {
            return dateSchedule
          }
        }
      }

      return null
    }
  },
  async mounted() {
    this.isFetching = true
    await Promise.all([
      attendanceScheduleModule.listAttendanceSchedules(),
      attendanceScheduleModule.listClass()
    ])
    this.isFetching = false
  }
})

